@import 'styles/card';
@import 'styles/grid';

@import 'variables';
@import 'mixins';

@import '@akelius/material-theme/src/style/typography';
@import '@akelius/material-theme/src/style/theme';

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

div.mat-expansion-panel-body {
  padding: 0 0 0 14px;
}

.mat-chip.mat-standard-chip {
  height: 24px;
  min-height: 24px;
}

.mat-standard-chip {
  min-height: 24px;
}

.mat-spinner {
  width: 100%;
  min-height: 20px;
  margin: auto;
}

// .container {
//   @include media-breakpoint-up(xxl) {
//     max-width: $max-content-width;
//   }

//   @include media-breakpoint-only(xl) {
//     max-width: 80%;
//   }
// }

.mat-form-field-wrapper {
  margin-right: $marginMiddleBig;
}

.results {
  display: flex;
  flex-wrap: wrap;
  margin-top: $marginBig;
}

.image-container {
  width: 100%;
  padding-bottom: 67%; // based on the aspect ratio of images 400x268 = 0.67
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

a.mat-flat-button {
  text-decoration: none;
}

.section-info {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $marginMiddle;
}

.section-info-item {
  display: flex;
  flex: 1;
  flex-direction: column;
}

mat-icon.warning {
  vertical-align: bottom;
  color: $status-warning-color;
}

.horizontal-radio mat-radio-group > mat-radio-button {
  margin-right: 15px;
}

.hidden {
  display: none;
}
