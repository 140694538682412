@use 'sass:math';
@use '@akelius/material-theme/src/style/sizes' as sizes;
@use './layout-bp' as layout;

$grid__cols: 12;

@mixin min-screen($breakpoint) {
  @if ($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (min-width: $breakpoint) {
      @content;
    }
  }
}
@mixin create-grid-col-classes($modifier, $grid-cols, $breakpoint) {
  @include min-screen($breakpoint) {
    @for $i from 1 through $grid-cols {
      .col#{$modifier}-#{$i} {
        flex-basis: math.div(100, math.div($grid-cols, $i)) * 1%;
      }
    }
  }
}

.container {
  margin: 0 auto;
  padding: 0 $small;

  @include layout.layout-bp(gt-md) {
    max-width: 80%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$small;
  margin-left: -$small;

  & > * {
    padding: 0 $small;
  }
}

@include create-grid-col-classes('', $grid__cols, 0);
@each $modifier, $breakpoint in map-remove(layout.$breakpoints, xs) {
  @include create-grid-col-classes(-#{$modifier}, $grid__cols, map-get($breakpoint, begin));
}
