@import '@akelius/material-theme/src/style/theme';
@import '@akelius/material-theme/src/style/sizes';

$marginBig: 24px;
$marginMiddleBig: 16px;
$marginMiddle: 12px;
$marginSmall: 8px;

$cardBorderStyle: 1px solid $line-color;

$header-height: 64px;
$footer-height: 45px;
