@import 'variables';

.card {
  border: $cardBorderStyle;
  padding-bottom: $marginMiddleBig;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 40px;
  height: calc(100% - 40px);

  &:focus {
    outline: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 0 $marginMiddle;

    > div {
      display: flex;
      flex-direction: column;
      margin-bottom: $marginMiddle;
    }
  }

  .mat-chip-list {
    margin-top: $marginMiddle;
  }
}
